import Dropzone from "dropzone";
import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage";
import {
  getMetaValue,
  toArray,
  findElement,
  removeElement,
  insertAfter
} from "../helpers";
import { findLastKey } from "lodash";

export default class DropZoneController extends Controller {
  static targets = ["input", "wrapper"];

  connect() {
    this.dropZone = createDropZone(this);
    this.hideFileInput();
    this.bindEvents();
    Dropzone.autoDiscover = false; // necessary quirk for Dropzone error in console
  }

  // Private
  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";
  }

  checkAssetValues() {    
    const btn = document.querySelector('#new-post-btn');
    const postComposer = document.querySelector('.new-post-form');
    const saleComposer = document.querySelector('#post_condition');
    let dmTextField = document.querySelector('#direct_message_content');

    if(postComposer){
      btn.value = "Uploading";
      if(btn.classList.contains('new-post-btn-active')){
        btn.classList.remove('new-post-btn-active')
      }
    }
    if(dmTextField){
      dmTextField.setAttribute('placeholder', 'Uploading File...');
      dmTextField.contentEditable = false;
      btn.style.pointerEvents = 'none';
      btn.querySelector('svg').classList.add('hide');
      btn.querySelector('img').classList.remove('hide');      
    }
    
    let blankCount = 0;
    let childNodes = this.wrapperTarget.children;

    for(const childNode of childNodes) {
      if((childNode.type == 'hidden') && (childNode.value.length == 0)){
        blankCount++;
      }
    }

    if(blankCount == 1) {
      if(postComposer && !saleComposer){ 
        console.log('Post Composer only')
        btn.value = "Post";
        if(!btn.classList.contains('new-post-btn-active')){
          btn.classList.add('new-post-btn-active');
        }      
        const postElement = document.getElementById('post_content');
        if (postElement) {
          const postContent = postElement.innerText;
          if(postContent == null || postContent == ""){
            if(btn.classList.contains('new-post-btn-active')){
              btn.classList.remove('new-post-btn-active');
            }
          }else{
            if(!btn.classList.contains('new-post-btn-active')){
              btn.classList.add('new-post-btn-active');
            }
          }
        }
        const messageElement = document.getElementById('direct_message_content');
        if (messageElement) {
          const messageContent = messageElement.innerText
          if(messageContent == null || messageContent == ""){
            btn.style.backgroundColor = 'lightgrey';
            btn.style.pointerEvents = 'none';
          }else{
            btn.style.backgroundColor = 'white';
            btn.style.pointerEvents = 'auto';
          }
        }
      }

      if(saleComposer){
        const postElement = document.getElementById('post_content');
        const postTitle = document.getElementById('post_title');
        const postCondition = document.getElementById('post_condition');
        const postPrice = document.getElementById('post_price');

        let requiredFields = [postElement, postTitle, postCondition, postPrice]

        requiredFields.forEach(field => {
          field.addEventListener('input', () => {
            if(postElement !== null && postTitle !== null && postCondition !== null && postPrice.value > 0){
              btn.classList.add('new-post-btn-active');
            } else {
              btn.classList.remove('new-post-btn-active');
            }
          })          
        });

        if(postElement !== null && postTitle !== null && postCondition !== null && postPrice.value > 0){
          btn.classList.add('new-post-btn-active');
        } else {
          btn.classList.remove('new-post-btn-active');
        }

        btn.value = "Post";
      }

      if(dmTextField){
        dmTextField.setAttribute('placeholder', 'Say Something...');
        dmTextField.contentEditable = true;
        btn.style.pointerEvents = 'auto';
        btn.querySelector('svg').classList.remove('hide');
        btn.querySelector('img').classList.add('hide');    
      }
    }
  }

  bindEvents() {

    this.wrapperTarget.addEventListener('DOMSubtreeModified', () => {
      this.checkAssetValues();
    }); 
   
    this.dropZone.on("addedfile", file => {
      setTimeout(() => {
        file.accepted && createDirectUploadController(this, file).start();
      }, 500);
      if(file.type == "video/mp4"){
        file.previewElement.childNodes[3].style.display = "block";
      }
    });

    this.dropZone.on("removedfile", file => {
      file.controller && removeElement(file.controller.hiddenInput);
    });

    this.dropZone.on("canceled", file => {
      file.controller && file.controller.xhr.abort();
    });
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url");
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 1;
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 256;
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles") || 'image/gif, image/heic, image/jpeg, image/png, image/webp, video/*';
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true;
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", event =>
      this.uploadRequestDidProgress(event)
    );
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element;
    const progress = (event.loaded / event.total) * 100;
    findElement(
      this.file.previewTemplate,
      ".dz-upload"
    ).style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
    Dropzone.dictCancelUpload = "Cancel Upload";
    const postBtn = document.querySelector('#new-post-btn');
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit("error", this.file, error);
    this.source.dropZone.emit("complete", this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit("success", this.file);
    this.source.dropZone.emit("complete", this.file);
  }

}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: 100,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    autoQueue: false,
    dictCancelUpload: "<span class='material-icons img-upload-cancel'>highlight_off</span>",
    dictRemoveFile: "<span class='material-icons img-upload-cancel'>highlight_off</span>",
    createImageThumbnails: true,
  });
}
